import React, { FunctionComponent } from "react"
import { Col } from "react-bootstrap"

interface SquareParams {
    value: string
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    isActive?: boolean
    readonly?: boolean
    highlighted?: boolean
    placeholder?: boolean
}

export const Square: FunctionComponent<SquareParams> = (props) => {
    const { value, onClick = () => { }, isActive = false, readonly = false, highlighted = false, placeholder = false } = props

    let className = "square-item";
    if (placeholder) {
        className += "-placeholder";
    } else if (highlighted) {
        className += "-highlight";
    } else if (readonly) {
        className += "-readonly";
    } else if (isActive) {
        className += "-active";
    }
    return (
        <Col className={className}>
            <div
                className="square-item-inner"
                onClick={onClick}
            >
                <span className="display-4 unselectable">
                    {value}
                </span>
            </div>
        </Col>
    )
}
