import React, { FunctionComponent } from "react";
import { Card, CardDeck, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

interface PuzzleInfo {
    name: string;
    summary: string;
    path: string;
    poster: string;
}

const puzzles: Array<PuzzleInfo> = [
    {
        name: "二零四八",
        summary: "相同数字的方块在移动中碰撞会合并，华尔街日报这称为数学极客的糖果传奇！",
        path: "/twothousand",
        poster: "https://resource.codemage.cn/poster-two-thousand.svg",
    },
    {
        name: "井字棋",
        summary: "井字棋最早可以追溯到古埃及，在公元前1300年左右的屋瓦上已出现了这种谜题板。",
        path: "/tictactoe",
        poster: "https://resource.codemage.cn/poster-tic-tac-toe.svg",
    },
    {
        name: "尼姆",
        summary: "又叫做为拈，起源于中国的捡石子，是一种两个人玩的回合制数学战略游戏",
        path: "/nim",
        poster: "https://resource.codemage.cn/poster-nim.svg",
    },
    {
        name: "猜数字",
        summary: "谜底是一组数码，根据每一次猜测给出的提示，逐渐缩小范围。",
        path: "/mastermind",
        poster: "https://resource.codemage.cn/poster-master-mind.svg",
    },
    {
        name: "二进制猜数",
        summary: "根据两组十进制提示填写零或一，从而在纵横方向都找出正确的二进制表示。",
        path: "/binarypuzzle",
        poster: "https://resource.codemage.cn/poster-binary-game.svg",
    },
]

export const FeaturedList: FunctionComponent = () => {
    return (
        <Container className="mt-5 mb-5">
            <h1 className="display-4 text-center mt-5 mb-5">
                特色谜题
            </h1>
            <p className="mt-4 mb-4">
                谜题虽然以娱乐的形式呈现，但往往需要严肃的数学推理或者逻辑思考，从而才能获得正确的答案。所以说，求解谜题是培养和锻炼计算思维的一种最有效和最有乐趣的途径。
            </p>

            <CardDeck>
                {
                    puzzles.map((puzzle, index) =>
                        <Card key={index} className="mt-4" style={{ minWidth: "18rem", maxWidth: "22rem" }}>
                            <Card.Img variant="top" src={puzzle.poster} width={390} height={200} />
                            <Card.Body>
                                <Card.Title>
                                    <Link to={puzzle.path} style={{ textDecoration: "none" }}>
                                        {puzzle.name}
                                    </Link>
                                </Card.Title>
                                <Card.Text>{puzzle.summary}</Card.Text>
                            </Card.Body>
                        </Card>
                    )
                }
            </CardDeck>
        </Container>
    );
}