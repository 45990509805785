
import React, { ChangeEvent, FormEvent, FunctionComponent, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { JwtResponse } from ".";
import { passportService } from "../service";
import { PassportActionType, PassportLoginAction } from "../store";

interface LocationState {
    referrer?: string;
}

export const LoginComponent: FunctionComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<LocationState>();

    const [validated, setValidated] = useState(false);
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [authenticated, setAuthenticated] = useState<boolean>();

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        let form = event.currentTarget;

        if (form.checkValidity() === true) {
            const response = await passportService().get("passport", {
                params: {
                    username: username,
                    password: password
                }
            });

            switch (response.status) {
                case 200:
                    console.info("Login successfully");

                    let transfer = response.data as JwtResponse;
                    dispatch<PassportLoginAction>({
                        type: PassportActionType.LOGIN,
                        username: transfer.username,
                        token: transfer.token,
                    });

                    history.push(location.state?.referrer ? location.state?.referrer : '/');

                    break;
                case 401:
                    console.warn("Invalid username or password");

                    setAuthenticated(false);
                    setTimeout(() => {
                        setAuthenticated(undefined)
                    }, 4000);

                    break;
            }
        } else {
            setValidated(true);
        }
    };

    const alert = () => {
        if (authenticated === false) {
            return (
                <Alert variant="danger" onClose={() => setAuthenticated(undefined)} dismissible>
                    <p>
                        错误的用户名或者密码！
                    </p>
                </Alert>
            );
        }
    }

    return (
        <Container>
            <Row className="mt-5">
                <Col xs={0} md={3}></Col>
                <Col xs={12} md={6}>
                    {alert()}

                    <Form noValidate validated={validated} onSubmit={submit}>
                        <Form.Control
                            required
                            autoComplete="off"
                            type="text"
                            placeholder="用户名"
                            className="mt-3 mb-3"
                            value={username || ""}
                            pattern=".{2,20}"
                            title="用户名要在2至20个字符之间"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                        />

                        <Form.Control
                            required
                            type="password"
                            placeholder="密码"
                            className="mt-3 mb-3"
                            value={password || ""}
                            pattern=".{6,20}"
                            title="密码要在6至20个字符之间"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        />

                        <Button variant="primary" type="submit">
                            登入
                            </Button>
                    </Form>
                </Col>
                <Col xs={0} md={3}></Col>
            </Row>
        </Container>
    )

}
