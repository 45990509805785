import React, { FunctionComponent } from "react"
import { Col } from "react-bootstrap"

interface SquareParams {
    value: string
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    isActive?: boolean
    selectable?: boolean
}

export const Square: FunctionComponent<SquareParams> = ( props ) => {
    const { value, onClick = () => {}, isActive = false, selectable = true } = props

    return (
        <Col className={ isActive ? "square-item-active" : "square-item" }>
            <div
                className="square-item-inner"
                onClick={ onClick }
            >
                <span className={selectable ? "display-4" : "display-4 unselectable"}>
                    { value }
                </span>
            </div>
        </Col>
    )
}
