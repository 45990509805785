import React, { FunctionComponent } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { VscMute, VscUnmute } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import useSound from "use-sound";
import { AppStore, SystemActionType } from "../store";

export const SoundEffect: FunctionComponent = () => {
    const system = useSelector((store: AppStore) => store.system);
    const dispatch = useDispatch();

    const [down] = useSound("https://resource.codemage.cn/pop-down.mp3");
    const [upOn] = useSound("https://resource.codemage.cn/pop-up-on.mp3");
    const [upOff] = useSound("https://resource.codemage.cn/pop-up-off.mp3");

    const toggle = () => {
        dispatch({ type: SystemActionType.SOUND });
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="submit">音效开关</Tooltip>}
        >
            <Button variant="link"
                onClick={toggle}
                onMouseDown={() => down()}
                onMouseUp={() => system.sound ? upOff() : upOn()}
            >
                {
                    system.sound ?
                        <VscUnmute size={24} style={{ color: "white" }} />
                        :
                        <VscMute size={24} style={{ color: "white" }} />
                }
            </Button>
        </OverlayTrigger>
    );
}