import { SystemAction, SystemActionType, SystemStore } from "./systemactions";

const initial: SystemStore = {
    sound: localStorage.getItem("sound-effect") === "false" ? false : true,
};

export function systemReducer(state = initial, action: SystemAction): SystemStore {
    switch (action.type) {
        case SystemActionType.SOUND:
            localStorage.setItem("sound-effect", state.sound ? "false" : "true");

            return {
                ...state,
                sound: !state.sound,
            };

        default:
            return state;
    }
}
