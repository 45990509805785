import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./app.css";
import { Navigator } from "./navigator";
import { ContentRouter } from "./router";

function App() {
    return (
        <div>
            <BrowserRouter>
                <Navigator />
                <ContentRouter />
            </BrowserRouter>
        </div>
    );
}

export default App;
