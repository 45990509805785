import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Figure, Media, Row } from "react-bootstrap";
import { VscTriangleDown, VscTriangleLeft, VscTriangleRight, VscTriangleUp } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useGesture } from "react-use-gesture";
import useSound from "use-sound";
import { PortraitType } from "../avatar";
import { AppStore } from "../store";
import "./style.css";

enum GameStatus {
    START,
    PROGRESS,
    END,
}

export const Board: FunctionComponent = () => {
    const GOAL = 2048;

    const system = useSelector((store: AppStore) => store.system);

    const [cells, setCells] = useState<number[]>(Array(16).fill(0));
    const [score, setScore] = useState<number>(0);
    const [added, setAdded] = useState<number>(0);
    const [status, setStatus] = useState<GameStatus>(GameStatus.START);

    const [on] = useSound("https://resource.codemage.cn/pop-up-on.mp3");
    const [off] = useSound("https://resource.codemage.cn/pop-up-off.mp3");

    /**
     *  Tiles slide as far as possible in the chosen direction until they are stopped by either another tile or the edge of the grid. 
     *  If two tiles of the same number collide while moving, they will merge into a tile with the total value of the two tiles that
     *  collided. The resulting tile cannot merge with another tile again in the same move. This is guaranteed by merged array.
     */
    const update = useCallback((key: string) => {
        if ([GameStatus.END, GameStatus.START].includes(status)) {
            return
        }

        const data: number[] = [...cells];
        const merged: number[] = [];

        switch (key) {
            case "ArrowLeft":
                for (let y = 0; y < 4; y++) {
                    for (let x = 1; x < 4; x++) {
                        let curr = y * 4 + x;
                        let next = curr - 1;

                        for (let z = x; z > 0; z--) {
                            if (data[next] === 0) {
                                data[next] = data[curr];
                                data[curr] = 0;
                            } else if (data[next] === data[curr]) {
                                if (merged.includes(curr) || merged.includes(next)) {
                                    continue;
                                }
                                data[next] = 2 * data[curr];
                                data[curr] = 0;
                                settle(data[next]);
                                merged.push(next);
                            }
                            curr = next;
                            next = curr - 1;
                        }
                    }
                }
                system.sound && on();
                break;

            case "ArrowRight":
                for (let y = 0; y < 4; y++) {
                    for (let x = 2; x >= 0; x--) {
                        let curr = y * 4 + x;
                        let next = curr + 1;

                        for (let z = 1; z < 4 - x; z++) {
                            if (data[next] === 0) {
                                data[next] = data[curr];
                                data[curr] = 0;
                            } else if (data[next] === data[curr]) {
                                if (merged.includes(curr) || merged.includes(next)) {
                                    continue;
                                }
                                data[next] = 2 * data[curr];
                                data[curr] = 0;
                                settle(data[next]);
                                merged.push(next);
                            }

                            curr = next;
                            next = curr + 1;
                        }
                    }
                }
                system.sound && off();
                break;

            case "ArrowUp":
                console.log("Pressed up arrow");

                for (let x = 0; x < 4; x++) {
                    for (let y = 1; y < 4; y++) {
                        let curr = y * 4 + x;
                        let next = curr - 4;

                        for (let z = 0; z < y; z++) {
                            if (data[next] === 0) {
                                data[next] = data[curr];
                                data[curr] = 0;
                            } else if (data[next] === data[curr]) {
                                if (merged.includes(curr) || merged.includes(next)) {
                                    continue;
                                }
                                data[next] = 2 * data[curr];
                                data[curr] = 0;
                                settle(data[next]);
                                merged.push(next);
                            }

                            curr = next;
                            next = curr - 4;
                        }
                    }
                }
                system.sound && on();
                break;

            case "ArrowDown":
                for (let x = 0; x < 4; x++) {
                    for (let y = 2; y >= 0; y--) {
                        let curr = y * 4 + x;
                        let next = curr + 4;

                        for (let z = 1; z < 4 - y; z++) {
                            if (data[next] === 0) {
                                data[next] = data[curr];
                                data[curr] = 0;
                            } else if (data[next] === data[curr]) {
                                if (merged.includes(curr) || merged.includes(next)) {
                                    continue;
                                }
                                data[next] = 2 * data[curr];
                                data[curr] = 0;
                                settle(data[next]);
                                merged.push(next);
                            }

                            curr = next;
                            next = curr + 4;
                        }
                    }
                }
                system.sound && off();
                break;
        }

        if (data.length > 0) {
            if (generate(data)) {
                setCells(data);
                if (data.find(x => x === GOAL)) {
                    setStatus(GameStatus.END);
                }
            } else {
                setCells(data);
                setStatus(GameStatus.END);
            }
        }
    }, [cells, status, on, off, system.sound]);

    const settle = (added: number) => {
        setAdded(added);
        setScore(score => score + added);
    }

    /**
     * Bind richer mouse and touch events to board component, such that user can swipe instead of arrow keys
     * with mobile devices.
     */
    const bind = useGesture({
        onDrag: ({ vxvy: [vx, vy], last }) => {
            const UPPER = 0.4, LOWER = 0.3;

            if (last && vx < -UPPER && Math.abs(vy) < LOWER) {
                update("ArrowLeft");
            } else if (last && vx > UPPER && Math.abs(vy) < LOWER) {
                update("ArrowRight");
            } else if (last && vy < -UPPER && Math.abs(vx) < LOWER) {
                update("ArrowUp");
            } else if (last && vy > UPPER && Math.abs(vx) < LOWER) {
                update("ArrowDown");
            }
        }
    });

    const init = () => {
        console.log("initializing game")

        setScore(0);

        let data = Array(16).fill(0);

        generate(data);
        generate(data);

        setCells(data);
        setStatus(GameStatus.PROGRESS);
    }

    const generate = (data: number[]) => {
        while (data.includes(0)) {
            const index = Math.floor(Math.random() * 16);
            if (data[index] === 0) {
                data[index] = Math.random() < 0.1 ? 4 : 2;
                return true;
            }
        }
        return false;
    }

    const start = () => {
        return (
            <Media>
                <Figure>
                    <Figure.Image
                        className="rounded-circle"
                        height={120}
                        width={120}
                        src={PortraitType.HAPPY}
                        alt="irene"
                    />
                </Figure>
                <Media.Body className="ml-4 mt-1">
                    <p>华尔街日报把这个游戏称为是数学极客的糖果传奇！</p>
                    <p>如果两个带有相同数字的方块在移动中碰撞，则会合并为一个方块且所带数字变为两者之和，当然也会有新方块出现，当值为2048的方块出现时游戏胜利。</p>
                    <Button onClick={init} variant="link" className="pl-0">游戏开始</Button>
                </Media.Body>
            </Media>
        );
    }

    const progress = () => {
        return (
            <Media>
                <Figure>
                    <Figure.Image
                        className="rounded-circle"
                        height={120}
                        width={120}
                        src={PortraitType.NORMAL}
                        alt="irene"
                    />
                </Figure>
                <Media.Body className="ml-4 mt-1">
                    <p>
                        <span>使用方向键让方块整体上下左右移动。</span>
                    </p>
                    <p>
                        {added > 0 ? <span>你又获得{added}分！</span> : undefined}
                        <span>你的总成绩是{score}分！</span>
                    </p>
                </Media.Body>
            </Media>
        );
    }

    const end = () => {
        return (
            <Media>
                <Figure>
                    <Figure.Image
                        className="rounded-circle"
                        height={120}
                        width={120}
                        src={PortraitType.NAUGHTY}
                        alt="irene"
                    />
                </Figure>
                <Media.Body className="ml-4 mt-1">
                    <p>
                        <span>游戏结束啦！</span>
                    </p>
                    <p>
                        <span>你的总成绩是{score}分！</span>
                        {
                            cells.find(x => x === GOAL) ? <span>你赢啦！</span> : <span>再接再厉！</span>
                        }
                    </p>
                    <p>
                        <Button onClick={init} variant="link" className="pl-0">再试一次</Button>
                    </p>
                </Media.Body>
            </Media>
        );
    }

    const content = () => {
        switch (status) {
            case GameStatus.START:
                return start();
            case GameStatus.PROGRESS:
                return progress();
            case GameStatus.END:
                return end();
        }
    }

    useEffect(() => {
        const keyboard = (event: KeyboardEvent) => {
            update(event.key);
        }

        document.addEventListener("keydown", keyboard);

        return () => {
            document.removeEventListener("keydown", keyboard);
        }
    }, [update]);

    return (
        <Container>
            {
                content()
            }

            <Container className="ml-0 pl-0 mb-4 mt-4" {...bind()}>
                {
                    [0, 1, 2, 3].map(y =>
                        <Row key={`row-${y}`} className="square-container">
                            {
                                [0, 1, 2, 3].map(x =>
                                    <Col key={`col-${x}`} className="square-item">
                                        <div className={`square-item-inner twothousand-cell-${cells[4 * y + x]}`}>
                                            {
                                                cells[4 * y + x]
                                            }
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    )
                }
            </Container>

            <Container className="ml-0 pl-0">
                <Row>
                    <Col style={{ minWidth: 220, maxWidth: 220 }}>
                        <Row xs={3} lg={3} >
                            <Col></Col>
                            <Col>
                                <Button className="m-1" variant="secondary" onClick={() => update("ArrowUp")}>
                                    <VscTriangleUp size={40} color="white" />
                                </Button>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row xs={3} lg={3}>
                            <Col>
                                <Button className="m-1" variant="secondary" onClick={() => update("ArrowLeft")}>
                                    <VscTriangleLeft size={42} color="white" />
                                </Button>
                            </Col>
                            <Col>
                                <Button className="m-1" variant="secondary" onClick={() => update("ArrowDown")}>
                                    <VscTriangleDown size={42} />
                                </Button>
                            </Col>
                            <Col>
                                <Button className="m-1" variant="secondary" onClick={() => update("ArrowRight")}>
                                    <VscTriangleRight size={42} />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}