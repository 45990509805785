import React, { FunctionComponent } from "react";
import { PuzzleFooter } from "../navigator";
import { FeaturedList } from "./feature";
import { ServiceOverview } from "./overview";
import { HorizontalSplitter } from "./splitter";

export const HomePage: FunctionComponent = () => {
    return (
        <>
            <ServiceOverview />
            <HorizontalSplitter />
            <FeaturedList />
            <PuzzleFooter />
        </>
    )
}
