import React, { FunctionComponent } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import { PuzzleFooter } from "../navigator";
import { Board } from "./board";

export const TicTacToe: FunctionComponent = () => {
    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>井字棋</h1>
                </Container>
            </Jumbotron>

            <Board />

            <PuzzleFooter />
        </>
    )
}
