import React, { FunctionComponent } from "react";
import { Col } from "react-bootstrap";

interface SquareParams {
    value: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const Square: FunctionComponent<SquareParams> = (props) => {
    return (
        <Col className="square-item">
            <div
                className="square-item-inner"
                onClick={props.onClick}
            >
                <h1 className="display-4">
                    {props.value}
                </h1>
            </div>
        </Col>
    )
}