import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Container, Figure, Media, Row, Button, Tab, Tabs, ButtonGroup, ToggleButton } from "react-bootstrap";
import { Square } from "./square";

enum TabType {
    RULE = "RULE",
    OPTION = "OPTION",
    ALGORITH = "ALGORITHM",
}

enum PortraitType {
    NORMAL = "https://resource.codemage.cn/portrait-irene-normal.svg",
    HAPPY = "https://resource.codemage.cn/portrait-irene-happy.svg",
    SAD = "https://resource.codemage.cn/portrait-irene-sad.svg",
    NAUGHTY = "https://resource.codemage.cn/portrait-irene-naughty.svg",
}

enum LevelType {
    Beginner,
    Skilled,
    Expert,
}

export const Board: FunctionComponent = () => {
    const [totalSolved, setTotalSolved] = useState<number>(0);
    const [oneSolved, setOneSolved] = useState<boolean>(false);
    const [level, setLevel] = useState<LevelType>(LevelType.Beginner);
    const [columns, setColumns] = useState<number>(3);
    const [rows, setRows] = useState<number>(3);
    const [squares, setSquares] = useState<number[]>(Array(columns * rows).fill(0));
    const [verticalResults, setVerticalResults] = useState<string[]>(Array(columns + 1).fill("0"));
    const [horizontalResults, setHorizontalResults] = useState<string[]>(Array(rows + 1).fill("0"));
    const [verticalMystery, setVerticalMystery] = useState<string[]>(Array(columns + 1).fill("0"));
    const [horizontalMystery, setHorizontalMystery] = useState<string[]>(Array(rows + 1).fill("0"));
    const [reset, setReset] = useState(true);
    const [tab, setTab] = useState("RULE")

    const calculateX = useCallback((matrix: number[], rows: number, columns: number) => {
        let horizontalResults: string[] = Array(rows + 1).fill("");
        for (let row = 0; row < rows; row++) {
            let startX = row * columns;
            let x = startX + columns;
            let horizontalResult = 0;
            let weight = 1;
            while (--x >= startX) {
                let n = matrix[x];
                horizontalResult += n * weight;
                weight *= 2;
            }
            horizontalResults[row] = horizontalResult.toString();
        }
        return horizontalResults;
    }, [])

    const calculateY = useCallback((matrix: number[], rows: number, columns: number) => {
        let verticalResults: string[] = Array(columns + 1).fill("");
        for (let column = 0; column < columns; column++) {
            let startY = column;
            let y = (rows - 1) * columns + column;
            let verticalResult = 0;
            let weight = 1;
            while (y >= startY) {
                verticalResult += matrix[y] * weight;
                y -= columns
                weight *= 2
            }
            verticalResults[column] = verticalResult.toString();
        }
        return verticalResults;
    }, [])

    const winner = useCallback((horizontalResults: string[], verticalResults: string[]) => {
        let countRightX = 0;
        for (let i = 0; i < rows; i++) {
            if (horizontalResults[i] === horizontalMystery[i]) {
                countRightX++;
            }
        }

        let countRightY = 0;
        for (let i = 0; i < columns; i++) {
            if (verticalResults[i] === verticalMystery[i]) {
                countRightY++;
            }
        }

        return countRightX === rows && countRightY === columns;
    }, [rows, columns, horizontalMystery, verticalMystery])

    const click = useCallback((i: number) => {
        let maxCount = columns * rows;
        if (i >= maxCount) {
            return;
        }
        let update = [...squares];

        let numOld = update[i];
        let numNew = numOld === 0 ? 1 : 0;
        update[i] = numNew;

        setSquares(update);

        let horizontalResults2 = calculateX(update, rows, columns);
        let verticalResults2 = calculateY(update, rows, columns);
        setHorizontalResults(horizontalResults2);
        setVerticalResults(verticalResults2);

        if (winner(horizontalResults2, verticalResults2)) {
            let totalSolved2 = totalSolved;
            setTotalSolved(totalSolved2 + 1);
            setOneSolved(true);
        }
    }, [totalSolved, rows, columns, squares, calculateX, calculateY, winner])

    /**
     * Reset the game with various initialization.
     */
    useEffect(() => {
        if (reset) {
            let columns2 = (level === 0 ? 3 : 4);
            let rows2 = (level === 0 ? 3 : 4);
            let count = rows2 * columns2;
            let update = Array(count).fill("");
            for (let i = 0; i < count; i++) {
                update[i] = Math.round(Math.random())
            }
            setHorizontalMystery(calculateX(update, rows2, columns2));
            setVerticalMystery(calculateY(update, rows2, columns2));
            setHorizontalResults(Array(rows + 1).fill(""));
            setVerticalResults(Array(columns + 1).fill(""));
            setSquares(Array(count).fill(0));
            setColumns(columns2);
            setRows(rows2);

            setReset(false);
        }
    }, [reset, level, rows, columns, squares, calculateX, calculateY]);

    const OneMoreTry: FunctionComponent = () => {
        return (
            <a
                href="!#"
                style={{ textDecoration: "none" }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault();
                    setTotalSolved(0);
                    setReset(true);
                }}
            >
                重新开始
            </a>
        );
    }

    const Status: FunctionComponent = () => {
        let portrait = oneSolved ? PortraitType.HAPPY : PortraitType.NORMAL;

        return (
            <Media>
                <Figure>
                    <Figure.Image
                        className="rounded-circle"
                        height={78}
                        width={78}
                        src={portrait}
                        alt="irene"
                    />
                </Figure>
                <Media.Body className="ml-4 mt-1">
                    {(() => {
                        let textScore = totalSolved === 0 ? "" : `已解决${totalSolved}轮。`
                        switch (portrait) {
                            case PortraitType.NORMAL:
                                return <p>加油！{textScore}<OneMoreTry /></p>
                            case PortraitType.HAPPY:
                                return <p>你{totalSolved <= 1 ? "" : "又"}赢啦！{textScore}<OneMoreTry /></p>
                        }
                    })()}

                </Media.Body>
            </Media>
        );
    }

    const Options: FunctionComponent = () => {
        return (
            <Tabs
                defaultActiveKey="rule"
                activeKey={tab}
                onSelect={(key) => setTab(key || "RULE")}
            >
                <Tab eventKey={TabType.RULE} title="规则">
                    <p className="mt-4">
                        二进制（Binary）是以2为底数的记数系统，历史源远流长。
                    </p>
                    <p>
                        埃及在分数中使用了二进制，印度使用二进制方法来研究韵律诗，中国《易经》中的卦象更是与二进制数字有对应关系。后来，弗朗西斯·培根（Francis Bacon）提出了一套系统可以把26个字母化为二进制数，而戈特弗里德·莱布尼兹（Gottfried Leibniz）在受到《易经》的启发后发表了基于二进制的算术。到了现代，二进制在现代计算机设备里得到了广泛的应用。
                    </p>
                    <p>
                        二进制谜题（Binary Puzzle）需要玩家选择0或者1，让横向和纵向的二进制表示出右侧和底部的数字，只有全部相等后才能够顺利通关，极富挑战性。
                    </p>
                </Tab>
                <Tab eventKey={TabType.OPTION} title="选项">
                    <p className="mt-4"></p>
                    <div className="mt-4">难度：
                        <ButtonGroup toggle>
                            {["初学", "熟练"].map((c, i) =>
                                <ToggleButton key={i}
                                    size="sm"
                                    type="radio"
                                    value={c}
                                    checked={level === i}
                                    onChange={() => {
                                        setLevel(i);
                                        setReset(true);
                                        setTotalSolved(0);
                                    }}
                                >{c.toString()}</ToggleButton>
                            )}
                        </ButtonGroup>
                        <span className="ml-3">{["（3位二进制：0 ~ 7）", "（4位二进制：0 ~ 15）"][level]}</span>
                    </div>
                </Tab>
            </Tabs>
        );
    }

    return (
        <Container>
            <Status />

            <Container fluid className="ml-0 pl-0 mb-4">
                {
                    horizontalMystery.map((correctX, row) => {
                        return (
                            <Row className="square-container-regular" key={row}>
                                {
                                    verticalMystery.map((correctY, column) => {
                                        let index = column + row * columns;
                                        let value = row === rows ? column === columns ? "" : correctY : column === columns ? correctX : squares[index].toString();
                                        let readonly = column === columns || row === rows;
                                        let isCorner = column === columns && row === rows;
                                        return (isCorner && oneSolved ?
                                            <div className="square-item-button">
                                                <Button size="lg" onClick={() => { setOneSolved(false); setReset(true); }}>下一局</Button>
                                            </div> :
                                            <Square value={value} onClick={row === rows || column === columns ? () => { } : () => click(index)}
                                                key={column}
                                                readonly={readonly}
                                                isActive={!readonly && value === "1"}
                                                highlighted={(column < columns && row === rows && correctY === verticalResults[column]) || (row < rows && column === columns && correctX === horizontalResults[row])}
                                                placeholder={isCorner && !oneSolved}
                                            />
                                        );
                                    })
                                }
                            </Row>
                        );
                    })
                }
            </Container>

            <Options />

        </Container>
    )
}
