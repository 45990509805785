export enum SystemActionType {
    SOUND = "SYSTEM_SOUND",
}


export interface SystemSoundAction {
    type: typeof SystemActionType.SOUND;
}

export type SystemAction = SystemSoundAction;

export interface SystemStore {
    readonly sound: boolean;
}