import React, { FunctionComponent } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BsGrid3X3Gap } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { SoundEffect } from "./soundeffect";
import { UserComponent } from "./user";

export const Navigator: FunctionComponent = () => {

    const home = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        window.open("https://www.codemage.cn");
    }

    return (
        <Navbar bg="primary" variant="dark" expand="lg">
            <Container>
                <LinkContainer to="https://www.codemage.cn" onClick={home} style={{ cursor: "pointer" }}>
                    <BsGrid3X3Gap size={22} color="white" className="mr-2 mt-1" />
                </LinkContainer>

                <LinkContainer to="/">
                    <Navbar.Brand>
                        谜题
                    </Navbar.Brand>
                </LinkContainer>

                <Navbar.Toggle aria-controls="mainframe" />

                <Navbar.Collapse id="mainframe">
                    <Nav className="mr-auto">
                        <NavDropdown title="棋盘" id="nav-dropdown-board">
                            <LinkContainer to="/tictactoe">
                                <NavDropdown.Item>
                                    井字棋
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/nim">
                                <NavDropdown.Item>
                                    尼姆
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                        <NavDropdown title="数学" id="nav-dropdown-math">
                            <LinkContainer to="/mastermind">
                                <NavDropdown.Item>
                                    猜数字
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/binarypuzzle">
                                <NavDropdown.Item>
                                    二进制猜数
                                </NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/twothousand">
                                <NavDropdown.Item>
                                    二零四八
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <SoundEffect />
                        <UserComponent />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );

}