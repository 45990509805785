import React, { FunctionComponent } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

export const ServiceOverview: FunctionComponent = () => {
    return (
        <Container className="mt-5 mb-5">
            <Row>
                <Col lg={4} xl={4}>
                    <h1 className="display-3 mt-3 mb-3">
                        谜题
                    </h1>
                    <p className="mb-3">
                        谜题是培养计算思维的最有效和最有乐趣的途径，通过不同种类和不同难度的谜题来挑战自己并拓展心智，把生命浪费在美好的事物上。
                    </p>
                </Col>
                <Col>
                    <Image src="https://resource.codemage.cn/codemage-puzzle-feature.svg" fluid />
                </Col>
            </Row>
        </Container>
    )
}