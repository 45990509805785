import axios from "axios";

export const passportService = () => {
    const service = axios.create({
        baseURL: process.env.NODE_ENV === "production" ? "https://account.codemage.cn" : "http://localhost:3030",
        validateStatus: (status: number) => (status >= 200 && status < 300) || status === 401 || status === 409,
    });

    return service;
}
