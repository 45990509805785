import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";

export const PuzzleFooter: FunctionComponent = () => {
    return (
        <Container>
            <p className="text-center mt-5 mb-5">
                &copy; 司码懿
            </p>
        </Container>
    );
}