import React, { FunctionComponent, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { WinningState } from "../avatar";
import { Heap } from "./heap";
import { Status } from "./status";

export const Board: FunctionComponent = () => {
    const [quantity, setQuantity] = useState(13);
    const [info, setInfo] = useState("");
    const [winning, setWinning] = useState(WinningState.UNKNWON);

    const action = (player: number) => {

        switch (quantity - player) {
            case 0:
                setWinning(WinningState.AI);
                setInfo(`哈，你拿走了最后一根火柴，你输了！`);
                break;
            case 1:
                setWinning(WinningState.PLAYER);
                setInfo(`哎，我拿到了最后一根火柴，我输了！`);
                break;
            default:
                const remain = (quantity - player - 1) % 4;
                const move = remain ? remain : Math.floor(Math.random() * 3) + 1;

                setInfo(`你拿走了${player}根火柴。我拿走了${move}根火柴。`);
                setQuantity(quantity - player - move);
                break;
        }

    }

    return (
        <Container>
            <Status info={info} winning={winning} />

            {
                [1, 2, 3].filter(num => num <= quantity).map(num =>
                    < Button key={num} size="lg" className="m-4" onClick={() => action(num)}>
                        {num}
                    </Button>
                )
            }

            <Heap quantity={quantity} />

        </Container >
    )
}
