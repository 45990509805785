import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";

interface HeapParams {
    quantity: number;
}

export const Heap: FunctionComponent<HeapParams> = (params) => {
    return (
        <Row>
            {
                Array.from({ length: params.quantity }, (value, key) => key).map(i =>
                    <Col key={i}>
                        <img width="20" alt="match" src="https://resource.codemage.cn/match.svg" />
                    </Col>
                )
            }
        </Row>
    )
}
