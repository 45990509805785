import React, { FunctionComponent } from "react";
import { Switch } from "react-router-dom";
import { HomePage } from "../home";
import { Nim } from "../nim";
import { LoginComponent } from "../passport";
import { TicTacToe } from "../tictactoe";
import { BinaryFun } from "../twothousand";
import { MasterMind } from "../mastermind";
import { BinaryPuzzle } from "../binarypuzzle";
import { PublicRoute } from "./statehouse";

export const ContentRouter: FunctionComponent = () => {
    return (
        <Switch>
            <PublicRoute path="/" component={HomePage} exact />
            <PublicRoute path="/tictactoe" component={TicTacToe} exact />
            <PublicRoute path="/nim" component={Nim} exact />
            <PublicRoute path="/twothousand" component={BinaryFun} exact />
            <PublicRoute path="/mastermind" component={MasterMind} exact />
            <PublicRoute path="/binarypuzzle" component={BinaryPuzzle} exact />
            <PublicRoute path="/login" component={LoginComponent} exact restricted />
        </Switch>
    );
}