import React, { FunctionComponent } from "react";
import { Figure, Media } from "react-bootstrap";
import { PortraitType, WinningState } from "../avatar";

interface StatusParams {
    info: string;
    winning: WinningState;
}

export const Status: FunctionComponent<StatusParams> = (params) => {

    const portrait = (winning: WinningState) => {
        switch (winning) {
            case WinningState.PLAYER:
                return PortraitType.SAD;
            case WinningState.AI:
                return PortraitType.NAUGHTY;
            default:
                return PortraitType.NORMAL;
        }
    }

    return (
        <Media>
            <Figure>
                <Figure.Image
                    className="rounded-circle"
                    height={78}
                    width={78}
                    src={portrait(params.winning)}
                    alt="irene"
                />
            </Figure>
            <Media.Body className="ml-4 mt-1">
                <p>
                    {params.info}
                </p>

                {
                    params.winning === WinningState.UNKNWON ?
                        <p>
                            你要拿走几根火柴？
                        </p>
                        :
                        <></>

                }
            </Media.Body>
        </Media >
    );
}