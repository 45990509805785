import React, { FunctionComponent, useEffect, useState, useRef } from "react"
import { ButtonGroup, Container, Figure, Media, Row, Tab, Tabs, ToggleButton, Button } from "react-bootstrap"
import { Square } from "./square"

enum TabType {
    RULE = "RULE",
    HOTKEY = "HOTKEY",
    OPTION = "OPTION",
    ALGORITH = "ALGORITHM",
}

enum PortraitType {
    NORMAL = "https://resource.codemage.cn/portrait-irene-normal.svg",
    HAPPY = "https://resource.codemage.cn/portrait-irene-happy.svg",
    SAD = "https://resource.codemage.cn/portrait-irene-sad.svg",
    NAUGHTY = "https://resource.codemage.cn/portrait-irene-naughty.svg",
}

enum LevelType {
    Beginner,
    Skilled,
    Expert,
}

let numbers: string[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]; // values to return

const shuffle = (array: string[]) => {
    var i = array.length,
        j = 0,
        temp;

    while (i--) {

        j = Math.floor(Math.random() * (i + 1));

        // swap randomly chosen element with current element
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;

    }

    return array;
};

export const Board: FunctionComponent = () => {
    const [level, setLevel] = useState<LevelType>(LevelType.Beginner);
    const [numbersCount, setNumbersCount] = useState<number>(level === LevelType.Beginner ? 3 : 4);

    const [mystery, setMystery] = useState<string[]>(Array(numbersCount).fill(""));
    const [answers, setAnswers] = useState<string[]>(Array(numbersCount).fill(""));
    const [results, setResults] = useState<number[]>([0, 0]);
    const [history, setHistory] = useState<string[][]>([]);
    const [reset, setReset] = useState(true);
    const [tab, setTab] = useState("RULE");
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const content = useRef<HTMLDivElement>(null);
    let [resultA, setResultA] = useState<number>(0);
    let [resultB, setResultB] = useState<number>(0);

    const activeLeft = () => {
        let newIndex = activeIndex - 1;
        setActiveIndex(newIndex < 0 ? numbersCount - 1 : newIndex);
    };

    const activeRight = () => {
        let newIndex = activeIndex + 1;
        setActiveIndex(newIndex > numbersCount - 1 ? 0 : newIndex);
    };

    const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (results[0] === numbersCount) {
            return;
        }
        let key = e.key;
        switch (key) {
            case "0":
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
                input(key);
                if (activeIndex < numbersCount - 1) {
                    activeRight();
                }
                break;
            case ">":
            case ".":
            case "ArrowRight":
                activeRight();
                break;
            case "<":
            case ",":
            case "ArrowLeft":
                activeLeft();
                break;
            case "Backspace":
                input("");
                if (activeIndex > 0) {
                    activeLeft();
                }
                break;
            case "Enter":
                submit();
                break;
            default:
                console.log(e.key);
                break;
        }
    };

    const input = (answerOne: string, index?: number) => {
        let answers2 = [...answers];
        answers2[index ?? activeIndex] = answerOne;
        setAnswers(answers2);
    }

    const submit = () => {
        content.current?.focus();
        if (!answers.every((answer, i, all) => {
            return (answer !== "");
        })) {
            return;
        }
        setActiveIndex(-1);
        let r = newAnswer();
        if (r[0] === numbersCount) {
            setResults(r);
            return;
        }
        history.unshift([...answers, r[0].toString(), "A", r[1].toString(), "B"]);
        setActiveIndex(0);
        setResults([0, 0]);
        setAnswers(Array(numbersCount).fill(""));
    };

    const newAnswer = () => {
        let answers2 = [...answers];
        let mystery2 = [...mystery];
        let resultA2 = 0;
        let resultB2 = 0;
        for (let i = 0; i < numbersCount; i++) {
            if (answers2[i] === mystery2[i]) {
                resultA2++;
                mystery2[i] = "";
                answers2[i] = "";
            }
        }
        for (let i = 0; i < numbersCount; i++) {
            if (mystery2[i] === "") {
                continue;
            }
            for (let j = 0; j < 4; j++) {
                if (answers2[j] === "") {
                    continue;
                }
                if (answers2[j] === mystery2[i]) {
                    resultB2++;
                    mystery2[i] = "";
                    answers2[j] = "";
                }
            }
        }
        setResultA(resultA2);
        setResultB(resultB2);
        return [resultA2, resultB2];
    };

    /**
     * Reset the game with various initialization.
     */
    useEffect(() => {
        if (reset) {
            setHistory([]);
            setResults([0, 0]);
            setResultA(0);
            setResultB(0);
            setAnswers(Array(numbersCount).fill(""));
            shuffle(numbers);
            let newMystery: string[] = [];
            for (let i = 0; i < numbersCount; i++) {
                if (level !== LevelType.Expert) {
                    newMystery.push(numbers[i]);
                } else {
                    shuffle(numbers);
                    newMystery.push(numbers[0]);
                }
            }
            console.log(newMystery)
            setMystery(newMystery);
            setActiveIndex(0);

            setReset(false);
        }
        (content.current)?.focus();

    }, [reset, numbersCount, level]);

    const OneMoreTry: FunctionComponent = () => {
        return (
            <a
                href="!#"
                style={{ textDecoration: "none" }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault();
                    setReset(true);
                }}
            >
                再来一次！
            </a>
        );
    };

    const Status: FunctionComponent = () => {
        let portrait;

        switch (resultA) {
            case 0:
                portrait = resultB === 0 ? PortraitType.SAD : PortraitType.NAUGHTY;
                break;
            case numbersCount:
                portrait = PortraitType.HAPPY;
                break;
            default:
                portrait = PortraitType.NAUGHTY;
                break;
        }

        return (
            <Media>
                <Figure>
                    <Figure.Image
                        className="rounded-circle"
                        height={78}
                        width={78}
                        src={portrait}
                        alt="irene"
                    />
                </Figure>
                <Media.Body className="ml-4 mt-1">
                    {(() => {
                        let tips = "";
                        if (level === LevelType.Beginner) {
                            if (resultA > 0) {
                                tips += "有" + resultA.toString() + "个数字完全正确。";
                            }
                            if (resultB > 0) {
                                tips += "有" + resultB.toString() + "个数字位置错了。";
                            }
                        }
                        switch (portrait) {
                            case PortraitType.HAPPY:
                                return <p>正确！<OneMoreTry /></p>;
                            case PortraitType.SAD:
                                return <p>加油！{tips}</p>;
                            case PortraitType.NAUGHTY:
                                return <p>不错！{tips}</p>;
                        }
                    })()}

                </Media.Body>
            </Media>
        );
    };

    const Options: FunctionComponent = () => {
        return (
            <Tabs
                defaultActiveKey="rule"
                activeKey={tab}
                onSelect={(key) => setTab(key || "RULE")}
            >
                <Tab eventKey={TabType.RULE} title="规则">
                    <p className="mt-4">
                        设定{numbersCount}位数码作为谜底，由你来猜。<br />每猜一组，会根据这组数码给出提示，提示以 x A y B 形式呈现，直到猜中为止。<br />其中 x 表示位置正确的数的个数，而 y 表示数字正确而位置不对的数的个数。
                    </p>
                    <div className="mt-4">
                        如果以4位数码作为谜底————
                        <ul>
                            <li>当谜底为 8123，而猜谜者猜 1052 时，提示为 0A2B。</li>
                            <li>当谜底为 5637，而猜谜者猜 4931 时，提示为 1A0B。</li>
                        </ul>
                    </div>
                </Tab>

                <Tab eventKey={TabType.HOTKEY} title="快捷键">
                    <div className="mt-4">
                        <ul>
                            <li>输入数字：数字键 <kbd>0</kbd> 至 <kbd>9</kbd></li>
                            <li>删除数字：退格键 <kbd>⌫</kbd></li>
                            <li>提交答案：回车键 <kbd>⏎</kbd></li>
                            <li>左移一格：方向键左 <kbd>←</kbd> 、逗号 <kbd>,</kbd> 、小于号<kbd>&lt;</kbd></li>
                            <li>右移一格：方向键右 <kbd>→</kbd> 、句号 <kbd>.</kbd> 、大于号<kbd>&gt;</kbd></li>
                        </ul>
                    </div>
                </Tab>

                <Tab eventKey={TabType.OPTION} title="选项">
                    <div className="mt-4" hidden>位数：
                        <ButtonGroup toggle>
                            {[2, 3, 4, 5].map((c, i) =>
                                <ToggleButton key={i}
                                    size="sm"
                                    type="radio"
                                    value={c}
                                    checked={numbersCount === c}
                                    onChange={() => {
                                        setNumbersCount(c)
                                        setReset(true)
                                    }}
                                >{c.toString()}</ToggleButton>
                            )}
                        </ButtonGroup></div>
                    <div className="mt-4">难度：
                        <ButtonGroup toggle>
                            {["初学", "熟练", "专家"].map((c, i) =>
                                <ToggleButton key={i}
                                    size="sm"
                                    type="radio"
                                    value={c}
                                    checked={level === i}
                                    onChange={() => {
                                        setLevel(i)
                                        setNumbersCount(i === 0 ? 3 : 4)
                                        setReset(true)
                                    }}
                                >{c.toString()}</ToggleButton>
                            )}
                        </ButtonGroup>
                        <span className="ml-3">{["3位数码每位不重复，有文字提示", "4位数码每位不重复，无文字提示", "4位数码可能有重复，无文字提示"][level]}</span>
                    </div>
                </Tab>

                <Tab eventKey={TabType.ALGORITH} title="算法">
                    <p className="mt-4">
                        可以通过推理来解题。这种解法的中心思想是假设猜的这个数字是正确答案，即如果它为正确答案，那么这个数应该符合已经猜测的数及其结果。
                    </p>
                    <p className="mt-4">
                        假如猜测1234而提示0A0B，那么下一步就不能猜含有1234中任一数字的数。
                    </p>
                    <p className="mt-4">
                        假如猜测5678并获得提示0A1B的话，则正确答案必须只包含5、6、7、8其中一个数字，且该数字位置不正确。
                    </p>
                    <p className="mt-4">
                        假设猜测1357并获得提示1A0B的话，则正确答案必须只包含1、3、5、7其中一个数字，且该数字的位置是正确的。
                    </p>
                    <p className="mt-4">
                        基于这个解法，经过一些计算机程式的大量模拟，猜到答案的平均次数大约为5.3次。显然地，如果运气够好，有机会可以在一次、两次猜到答案；运气不好之时，花费八次猜测才找到答案也不无可能。
                    </p>
                </Tab>
            </Tabs>
        );
    };

    return (
        <Container>
            <Status />

            <Container ref={content} className="ml-0 pl-0 mb-4" onKeyUp={keyPress} tabIndex={0}>
                <Row className={(resultA !== numbersCount) ? "square-container-fullwidth" : "square-container-fullwidth square-container-highlight"}>
                    {answers.map((answer, i) =>
                        <Square value={answer} isActive={activeIndex === i} selectable={false} key={i}
                            onClick={() => {
                                if (resultA !== numbersCount) {
                                    setActiveIndex(i);
                                    let n = answer === "" ? 0 : (parseInt(answer) + 1);
                                    if (n === 10) {
                                        n = 0;
                                    }
                                    input(n.toString(), i);
                                }
                            }}
                        />
                    )}
                    <Button onClick={() => submit()}>确定</Button>
                    <Square value={results[0].toString()} />
                    <Square value={"A"} />
                    <Square value={results[1].toString()} />
                    <Square value={"B"} />
                </Row>
                {history.map((record, n) =>
                    <Row className="square-container-fullwidth" key={n}>
                        {record.slice(0, numbersCount).map((answer, i) =>
                            <Square value={answer} key={i} />
                        )}
                        <Button className="col-separate" disabled>前 {n + 1} 回</Button>
                        {record.slice(numbersCount).map((result, i) =>
                            <Square value={result} key={i} />
                        )}
                    </Row>
                )}
            </Container>

            <Options />

        </Container>
    );
};
