import { combineReducers, createStore, Store } from "redux";
import { SystemStore } from "./systemactions";
import { systemReducer } from "./systemreducer";
import { PassportStore } from "./passportactions";
import { passportReducer } from "./passportreducer";

export * from "./systemactions";
export * from "./systemreducer";
export * from "./passportactions";
export * from "./passportreducer";

export interface AppStore {
    system: SystemStore;
    passport: PassportStore;
}

export const rootReducer = combineReducers({
    system: systemReducer,
    passport: passportReducer,
});

export const configureStore = (): Store<AppStore> => {
    return createStore(rootReducer);
}