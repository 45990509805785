import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ButtonGroup, Container, Figure, Media, Row, Tab, Tabs, ToggleButton } from "react-bootstrap";
import { Square } from "./square";

enum PlayerType {
    HUMAN = "玩家",
    AI = "电脑",
}

enum SymbolType {
    X = "X",
    O = "O",
}

enum TabType {
    RULE = "RULE",
    OPTION = "OPTION",
    ALGORITH = "ALGORITHM",
}

enum PortraitType {
    NORMAL = "https://resource.codemage.cn/portrait-irene-normal.svg",
    HAPPY = "https://resource.codemage.cn/portrait-irene-happy.svg",
    SAD = "https://resource.codemage.cn/portrait-irene-sad.svg",
    NAUGHTY = "https://resource.codemage.cn/portrait-irene-naughty.svg",
}

export const Board: FunctionComponent = () => {
    const [squares, setSquares] = useState<string[]>(Array(9).fill(""));
    const [offensive, setOffensive] = useState(PlayerType.AI);
    const [symbol, setSymbol] = useState(SymbolType.X);
    const [turn, setTurn] = useState(PlayerType.AI);
    const [reset, setReset] = useState(false);
    const [tab, setTab] = useState("RULE")

    const click = (i: number) => {
        // ignore if someone has won the game or the square is already filled
        if (winner(squares) || squares[i]) {
            return;
        }

        // update the squares
        move(i);

        // set to AI's turn
        setTurn(PlayerType.AI);
    }

    /**
     * Take the specific move.
     */
    const move = useCallback((i: number) => {
        let update = [...squares];

        update[i] = symbol;

        setSquares(update);
        setSymbol(symbol === SymbolType.X ? SymbolType.O : SymbolType.X);
    }, [squares, symbol]);

    /**
     * To consider all the possible ways the game can go and returns the best value for that move, assuming the opponent also plays optimally.
     * 
     * AI might choose to make a move which will result in a slower victory or a faster loss. To overcome this problem we subtract the depth value 
     * from the evaluated score. This means that in case of a victory it will choose a the victory which takes least number of moves and in case of
     * a loss it will try to prolong the game and play as many moves as possible.
     * 
     * @param squares the trial squares
     * @param depth the depth of the game tree
     * @param maximizing whether it is maximizing or minimizing
     */
    const minimax = useCallback((squares: Array<string>, depth: number, maximizing: boolean) => {
        switch (winner(squares)) {
            case "X":
                return offensive === PlayerType.AI ? 10 - depth : -10 + depth;
            case "O":
                return offensive === PlayerType.AI ? -10 + depth : 10 - depth;
            case "TIE":
                return 0;
            case null:
                let trial = [...squares];
                if (maximizing) {
                    let best = -Infinity;
                    for (let i = 0; i < trial.length; i++) {
                        if (trial[i] === "") {
                            trial[i] = offensive === PlayerType.AI ? "X" : "O";
                            let score = minimax(trial, depth + 1, false);
                            best = Math.max(best, score);
                            trial[i] = "";
                        }
                    }
                    return best;
                } else {
                    let best = Infinity;
                    for (let i = 0; i < trial.length; i++) {
                        if (trial[i] === "") {
                            trial[i] = offensive === PlayerType.AI ? "O" : "X";
                            let score = minimax(trial, depth + 1, true);
                            best = Math.min(best, score);
                            trial[i] = "";
                        }
                    }
                    return best;
                }
            default:
                throw new Error("Unexpected state");
        }
    }, [offensive]);

    /**
     * Event handler for the state when AI takes action.
     */
    useEffect(() => {
        // ignore if someone has won the game
        if (winner(squares)) {
            return;
        }

        if (turn === PlayerType.AI) {
            console.log("ai is thinking");

            // find the best move via minimax algorithm
            let trial = [...squares];
            let max = -Infinity;
            let best = 0;
            for (let i = 0; i < trial.length; i++) {
                if (trial[i] === "") {
                    trial[i] = offensive === PlayerType.AI ? "X" : "O";
                    let score = minimax(trial, 0, false);
                    console.log("score", score, "move", i);
                    if (score > max) {
                        max = score;
                        best = i;
                    }
                    trial[i] = "";
                }
            }
            console.log("ai took move on", best, "with score", max);

            // make the move
            move(best);

            // finish the turn
            setTurn(PlayerType.HUMAN);
        }
    }, [turn, minimax, move, squares, offensive]);

    /**
     * Reset the game with various initialization.
     */
    useEffect(() => {
        if (reset) {
            setSquares(Array(9).fill(""));

            setTurn(offensive === PlayerType.AI ? PlayerType.AI : PlayerType.HUMAN);
            setSymbol(SymbolType.X);

            setReset(false);
        }
    }, [reset, offensive]);

    const winner = (squares: Array<string>) => {
        const lines = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];

        // try to find the obvious winner
        for (let i = 0; i < lines.length; i++) {
            const [a, b, c] = lines[i];
            if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
                return squares[a];
            }
        }

        // this is a withdraw
        if (squares.includes('') === false) {
            return "TIE";
        }

        // no winner as for now
        return null;
    }

    const OneMoreTry: FunctionComponent = () => {
        return (
            <a
                href="!#"
                style={{ textDecoration: "none" }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault();
                    setReset(true);
                }}
            >
                再来一次！
            </a>
        );
    }

    const Status: FunctionComponent = () => {
        let state = winner(squares);
        let portrait;

        switch (state) {
            case "X":
                portrait = offensive === PlayerType.HUMAN ? PortraitType.HAPPY : PortraitType.SAD;
                break;
            case "O":
                portrait = offensive === PlayerType.AI ? PortraitType.HAPPY : PortraitType.SAD;
                break;
            case "TIE":
                portrait = PortraitType.NAUGHTY;
                break;
            case null:
                portrait = PortraitType.NORMAL;
                break;
            default:
                throw new Error("Unexpected state");
        }

        return (
            <Media>
                <Figure>
                    <Figure.Image
                        className="rounded-circle"
                        height={78}
                        width={78}
                        src={portrait}
                        alt="irene"
                    />
                </Figure>
                <Media.Body className="ml-4 mt-1">
                    {(() => {
                        switch (portrait) {
                            case PortraitType.HAPPY:
                                return <p>你赢啦！<OneMoreTry /></p>
                            case PortraitType.SAD:
                                return <p>你输啦！赶快调整你的算法哟！<OneMoreTry /></p>
                            case PortraitType.NAUGHTY:
                                return <p>平局！<OneMoreTry /></p>
                            case PortraitType.NORMAL:
                                return <p>现在请{turn}执{symbol}行动。</p>
                        }
                    })()}

                </Media.Body>
            </Media>
        );
    }

    const Options: FunctionComponent = () => {
        return (
            <Tabs
                defaultActiveKey="rule"
                activeKey={tab}
                onSelect={(key) => setTab(key || "RULE")}
            >
                <Tab eventKey={TabType.RULE} title="规则">
                    <p className="mt-4">
                        井字棋（Tic Tac Toe）需要两个玩家，先手者用X后手者用O，轮流在3×3的格子上打自己的符号，最先以横、直、斜连成一线则为胜，否则将以和局告终。
                    </p>
                    <p>
                        由于棋盘一般不画边框，格线排成井字故得名。这种类型的棋类谜题最早可以追溯到古埃及，在公元前1300年左右的屋瓦上发现了这种谜题板。
                    </p>
                </Tab>

                <Tab eventKey={TabType.OPTION} title="选项">
                    <p className="mt-4"></p>
                        先手：
                        <ButtonGroup toggle>
                        <ToggleButton
                            size="sm"
                            type="radio"
                            value={PlayerType.HUMAN}
                            checked={offensive === PlayerType.HUMAN}
                            onChange={() => {
                                setOffensive(PlayerType.HUMAN);
                                setReset(true);
                            }}
                        >
                            玩家
                        </ToggleButton>

                        <ToggleButton
                            size="sm"
                            type="radio"
                            value={PlayerType.AI}
                            checked={offensive === PlayerType.AI}
                            onChange={() => {
                                setOffensive(PlayerType.AI);
                                setReset(true);
                            }}
                        >
                            电脑
                        </ToggleButton>
                    </ButtonGroup>
                </Tab>

                <Tab eventKey={TabType.ALGORITH} title="算法">
                    <p className="mt-4">井字棋因为变化简单，常成为博弈论和游戏树搜寻的教学例子。学生要从既有的玩法中归纳出致胜之道，并将算法编写为程序，让电脑与用户对弈。</p>

                    <p>井字棋空间复杂度是765、搜索复杂度是26,830，如果双方都是采用了最佳策略，结果应该以平局告终。在电影《战争游戏》中，人工智能WOPR通过井字棋悟到了核战争之中没有赢家这一道理。</p>
                </Tab>
            </Tabs>
        );
    }

    return (
        <Container>
            <Status />

            <Container fluid className="ml-0 pl-0 mb-4">
                <Row className="square-container">
                    <Square value={squares[0]} onClick={() => click(0)} />
                    <Square value={squares[1]} onClick={() => click(1)} />
                    <Square value={squares[2]} onClick={() => click(2)} />
                </Row>
                <Row className="square-container">
                    <Square value={squares[3]} onClick={() => click(3)} />
                    <Square value={squares[4]} onClick={() => click(4)} />
                    <Square value={squares[5]} onClick={() => click(5)} />
                </Row>
                <Row className="square-container">
                    <Square value={squares[6]} onClick={() => click(6)} />
                    <Square value={squares[7]} onClick={() => click(7)} />
                    <Square value={squares[8]} onClick={() => click(8)} />
                </Row>
            </Container>

            <Options />

        </Container>
    )
}
