import React, { FunctionComponent } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import { PuzzleFooter } from "../navigator";
import { Board } from "./board";

export const BinaryPuzzle: FunctionComponent = () => {
    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>二进制猜数</h1>
                </Container>
            </Jumbotron>

            <Board />

            <PuzzleFooter />
        </>
    )
}
