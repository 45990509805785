import React, { FunctionComponent } from "react";
import { NavDropdown } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppStore, PassportActionType } from "../store";

export const UserComponent: FunctionComponent = () => {
    const passport = useSelector((store: AppStore) => store.passport);
    const dispatch = useDispatch();
    const history = useHistory();

    const icon = <AiOutlineUser size={24} style={{ color: "white" }} />;

    const logging = () => {
        if (passport.authenticated) {
            dispatch({
                type: PassportActionType.LOGOUT,
            })

            history.push("/");
        } else {
            history.push("/login");
        }
    }

    return (
        <NavDropdown title={icon} id="nav-dropdown-ai">
            <NavDropdown.Item disabled>
                用户
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>
                {passport.username}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logging}>
                {passport.authenticated ? "登出" : "登入"}
            </NavDropdown.Item>
        </NavDropdown>
    );
}